import React, { useState, useEffect} from 'react';
import { Table, Breadcrumb, Button, Nav, Tab, Container, Row, Col, Form, Stack, Spinner, Modal } from 'react-bootstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table'
import moment from 'moment';
import 'moment/locale/en-au';
import Select from 'react-select';
import EditTimesheet from './EditTimesheet';
import RemoveApproval from './RemoveApproval';
import DatePicker from 'react-datepicker';
import { paginationOptions, customStyles } from '../../data/Data.js'


export default function Timesheet() {

    useEffect(() => {
        document.title = "Portal – Timesheets";
    }, []);

    const Parse = require('parse');
    const currentUser = Parse.User.current();

    const [loadedAdmin, setLoadedAdmin] = useState(false);
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [times, setTimes] = useState([]);
    const [isTimesLoaded, setIsTimesLoaded] = useState(false);
    const [isTimesTableLoaded, setIsTimesTableLoaded] = useState(false);
    const [leave, setLeave] = useState([]);
    const [isLeaveLoaded, setIsLeaveLoaded] = useState(false);
    const [isLeaveTableLoaded, setIsLeaveTableLoaded] = useState(false);

    const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);
    const [projectOptions, setProjectOptions] = useState([]);

    const [staffOptions, setStaffOptions] = useState([]);
    const [userFilter, setUserFilter] = useState({
        "value" : currentUser.id,
        "label" : currentUser.attributes.firstName + ' ' + currentUser.attributes.lastName,
        "object" : currentUser
    });
    const [userFilterValue, setUserFilterValue] = useState(currentUser.id);

    const [key, setKey] = useState('times');

    const today = new Date();
    const dateToday = today.getDate();
    const thisMonth = new Date(today);
    const lastMonth = new Date(today);

    thisMonth.setHours(23,59,59);

    if (dateToday < 15) {
        lastMonth.setHours(0,0,0);
        lastMonth.setDate(16);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
    } else {
        lastMonth.setHours(0,0,0);
        lastMonth.setDate(16);
        // lastMonth.setMonth(lastMonth.getMonth() - 1);
        // thisMonth.setDate(15);
    }

    const [dateStart, setDateStart] = useState(lastMonth);
    const [dateEnd, setDateEnd] = useState(thisMonth);
    const [approvedSum, setApprovedSum] = useState(0);
    const [pendingSum, setPendingSum] = useState(0);

    const queryAdmin = () => {
        if (!loadedAdmin) {
            if (currentUser.id === 'WiGacaXnel' || currentUser.id === 'BKD0LlA2EC' || currentUser.id === 'NS7ecaLkAU') {
            // if (currentUser.id === 'abc' || currentUser.id === '123' || currentUser.id === 'xyz') {
                setUserIsAdmin(true);
                setLoadedAdmin(true);
                queryStaff();
                queryProjects();
                queryAllTimes();
                queryAllLeave();
            } else {
                queryProjects();
                queryUsersTimes();
                queryUsersLeave();
            }
        }
    }

    useEffect(() => {
        queryAdmin(); 
    },[])

    const queryStaff = () => {
        const query = new Parse.Query(Parse.Role);
        query.equalTo('name','projectManagers').first().then(staff => { 
            const users = staff.getUsers().query();
            users.equalTo('status', 'Active');
            users.ascending('firstName');
            users.find()
            .then((userQueryRes) => {
                for (let i = 0; i < userQueryRes.length; i++) {
                    if (currentUser.id === userQueryRes[i].id) {
                        var thisUser = userQueryRes.splice(i,1);
                        userQueryRes.unshift(thisUser[0]); 
                        break;
                    }
                }
                userQueryRes.pop();
                var options = userQueryRes.map(user => ({
                    "value" : user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName,
                    "object" : user
                }));
                setStaffOptions(options);
            }, (error) => {
                alert(error);
            })
        }, (error) => {
            alert(error);
        })
    }

    const queryProjects = () => {
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);

        const Project = Parse.Object.extend('Project');

        const subQuery1 = new Parse.Query(Project);
        subQuery1.containedIn("status", ["Complete", "Loss"]);
        subQuery1.greaterThanOrEqualTo('completedOn', lastWeek);

        const subQuery2 = new Parse.Query(Project);
        subQuery2.containedIn("status", ["Quoting", "Undecided", "Lead", "FP on hold", "Active", "Site Inspection", "Internal Review", "C10 Review", "SD Review", "On hold"]);

        const query = new Parse.Query.or(subQuery1, subQuery2);

        query.limit(999999);
        query.includeAll();
        query.ascending('projectId');
        query.find()
        .then(function(res) {
            var options = res.map(project => ({
                "value" : project.id,
                "label" : project.attributes.projectId + ' — ' + project.attributes.name,
                "object" : project,
                "number" : project.attributes.projectId
            }));
            options.unshift({
                "value" : 'null',
                "label" : 'No project',
                "object" : 'null',
                "number" : ''
            })
            setProjectOptions(options);
            setIsProjectsLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const queryUsersTimes = () => {
        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.limit(999999);
        query.equalTo('user', currentUser);
        query.equalTo('leave', false);
        query.ascending('start');
        query.find()
        .then(function(res) {
            setTimes(res);
            setIsTimesLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const queryUsersLeave = () => {
        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.limit(999999);
        query.equalTo('user', currentUser);
        query.equalTo('leave', true);
        query.ascending('start');
        query.find()
        .then(function(res) {
            setLeave(res);
            setIsLeaveLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const queryAllTimes = () => {
        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.limit(999999);
        query.equalTo('leave', false);
        query.descending('start');
        query.find()
        .then(function(res) {
            setTimes(res);
            setIsTimesLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const queryAllLeave = () => {
        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.limit(999999);
        query.equalTo('leave', true);
        query.descending('start');
        query.find()
        .then(function(res) {
            setLeave(res);
            setIsLeaveLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const getTimesData = () => {

        var data2 = [];

        for (var i = 0; i < times.length; i++) {
            var sheet = times[i];
            var endString = '';
            var approvedString = '';
            var approvedByString = '';
            var isApproved = false;

            var startValDisp = sheet.get('start');
            var locationDisp = sheet.get('location');

            var endValDisp;

            var person = sheet.get('user').id;

            if (sheet.get('approved') === true) {
                approvedString = <i className="fas fa-check"></i>;
                isApproved = true;
                if (sheet.get('approvedBy') !== undefined) {
                    approvedByString = sheet.get('approvedBy').attributes.firstName + ' ' + sheet.get('approvedBy').attributes.lastName;
                }            
            }

            if (sheet.get('active') === true) {
                isApproved = true;
            } else {
                endValDisp = sheet.get('end');
                endString = moment(endValDisp).locale('en-au').calendar({
                    // sameDay: '[Today]',
                    // nextDay: '[Tomorrow]',
                    nextWeek: 'ddd DD MMM h:mm A',
                    // lastDay: '[Yesterday]',
                    lastWeek: 'ddd DD MMM h:mm A',
                    sameElse: 'ddd DD MMM h:mm A'   
                });
            }

            var formString = 'sheet:' + person + ':' + sheet.id + ':' + i;

            data2.push({
                id: i,
                idDisplay: sheet.id,
                personDisplay: person,
                locationDisplay: locationDisp,
                startValueDisplay: startValDisp,
                startDisplay: moment(startValDisp).locale('en-au').calendar({
                    // sameDay: '[Today]',
                    // nextDay: '[Tomorrow]',
                    nextWeek: 'ddd DD MMM h:mm A',
                    // lastDay: '[Yesterday]',
                    lastWeek: 'ddd DD MMM h:mm A',
                    sameElse: 'ddd DD MMM h:mm A'   
                }),
                endValueDisplay: endValDisp,
                endDisplay: endString,
                breakDisplay: sheet.get('breakHours'),
                hoursDisplay: sheet.get('hours'),
                approvedDisplay: approvedString,
                approvedByDisplay: approvedByString,
                isEditableDisplay: isApproved,
                formDisplay: formString,
            })
        }

        return data2;
    }

    const [timesData, setTimesData] = React.useState(React.useMemo(() => getTimesData, [isTimesLoaded]));
    if (!isTimesTableLoaded) {
        if (times.length > 0) {
            setTimesData(getTimesData);
            setIsTimesTableLoaded(true);
        }
    }

    const timesCols = React.useMemo(
        () => [
            {
                Header: 'Timesheet Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: 'Is Editable',
                accessor: 'isEditableDisplay',
                show: false,
            },
            {
                Header: 'Form',
                accessor: 'formDisplay',
                show: false,
            },
            {
                Header: 'User id',
                accessor: 'personDisplay',
                show: false,
                // Filter: FakeColumnFilter,
                // filter: 'includesSome',
            },
            {
                Header: 'Location',
                accessor: 'locationDisplay',
                show: false,
            },
            {
                Header: 'Start Value',
                accessor: 'startValueDisplay',
                show: false,
                filter: 'between',
            },
            {
                Header: 'End Value',
                accessor: 'endValueDisplay',
                show: false,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Start</span>,
                accessor: 'startDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>End</span>,
                accessor: 'endDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Break (h)</span>,
                accessor: 'breakDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Hours</span>,
                accessor: 'hoursDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header: "",
                id: "edit",
                accessor: "editDisplay",
                Cell: (tableProps) => <>
                    <EditTimesheet
                        row={tableProps.row}
                        timesData={[...timesData]}
                        setTimesData={setTimesData}
                        projectOptions={projectOptions}
                        isEditable={tableProps.row.values.isEditableDisplay}
                        currentUser={currentUser}
                    />
                    
                </>
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}></span>,
                accessor: 'approvedDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--success)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Approved By</span>,
                accessor: 'approvedByDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header: "",
                id: "approve",
                accessor: "approveDisplay",
                Cell: (tableProps) => <>
                    {(userIsAdmin && !tableProps.row.values.isEditableDisplay) && (
                        <Form>
                            <Form.Check
                                id={tableProps.row.values.formDisplay}
                            />
                        </Form>
                    )}
                    {(userIsAdmin && tableProps.row.values.isEditableDisplay) && (
                        <RemoveApproval
                            row={tableProps.row}
                            timesData={[...timesData]}
                            setTimesData={setTimesData}
                            calcHours={calcHours}
                        />
                    )}
                </>
            },
        ],
        [timesData, projectOptions]
    );

    const getLeaveData = () => {

        var data2 = [];

        for (var i = 0; i < leave.length; i++) {
            var sheet = leave[i];
            var person = sheet.get('user').id;
            var approvedString = '';
            var approvedByString = '';
            var isApproved = false;

            if (sheet.get('approved') === true) {
                approvedString = <i className="fas fa-check"></i>;
                isApproved = true;
                if (sheet.get('approvedBy') !== undefined) {
                    approvedByString = sheet.get('approvedBy').attributes.firstName + ' ' + sheet.get('approvedBy').attributes.lastName;
                }
            }

            var formString = 'leave:' + person + ':' + sheet.id  + ':' + i;
            data2.push({
                id: i,
                idDisplay: sheet.id,
                personDisplay: person,
                typeDisplay: sheet.get('leaveType') + ' leave',
                startDisplay: moment(sheet.get('start')).locale('en-au').calendar({
                    sameDay: 'ddd DD MMM',
                    nextDay: 'ddd DD MMM',
                    nextWeek: 'ddd DD MMM',
                    lastDay: 'ddd DD MMM',
                    lastWeek: 'ddd DD MMM',
                    sameElse: 'ddd DD MMM'  
                }),
                endDisplay: moment(sheet.get('end')).locale('en-au').calendar({
                    sameDay: 'ddd DD MMM',
                    nextDay: 'ddd DD MMM',
                    nextWeek: 'ddd DD MMM',
                    lastDay: 'ddd DD MMM',
                    lastWeek: 'ddd DD MMM',
                    sameElse: 'ddd DD MMM'  
                }),
                hoursDisplay: sheet.get('hours'),
                approvedDisplay: approvedString,
                approvedByDisplay: approvedByString,
                formDisplay: formString,
                isEditableDisplay: isApproved,
            })
        }

        return data2;
    }

    const [leaveData, setLeaveData] = React.useState(React.useMemo(() => getLeaveData, [isLeaveLoaded]));
    if (!isLeaveTableLoaded) {
        if (leave.length > 0) {
            setLeaveData(getLeaveData);
            setIsLeaveTableLoaded(true);
        }
    }

    const leaveCols = React.useMemo(
        () => [
            {
                Header: 'Timesheet Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: 'Is Editable',
                accessor: 'isEditableDisplay',
                show: false,
            },
            {
                Header: 'Form',
                accessor: 'formDisplay',
                show: false,
            },
            {
                Header: 'User id',
                accessor: 'personDisplay',
                show: false,
                // Filter: FakeColumnFilter,
                // filter: 'includesSome',
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Type</span>,
                accessor: 'typeDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Start</span>,
                accessor: 'startDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>End</span>,
                accessor: 'endDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Hours</span>,
                accessor: 'hoursDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}></span>,
                accessor: 'approvedDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--success)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Approved By</span>,
                accessor: 'approvedByDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            
            // {
            //     Header: "",
            //     id: "edit",
            //     accessor: "editDisplay",
            //     Cell: (tableProps) => <>
            //         <EditLeave
            //             row={tableProps.row}
            //             timesData={[...timesData]}
            //             setTimesData={setTimesData}
            //         />
            //     </>
            // },
            {
                Header: "",
                id: "approve",
                accessor: "approveDisplay",
                Cell: (tableProps) => <>
                    {(userIsAdmin && !tableProps.row.values.isEditableDisplay) && (
                        <Form>
                            <Form.Check
                                id={tableProps.row.values.formDisplay}
                            />
                        </Form>
                    )}
                    {(userIsAdmin && tableProps.row.values.isEditableDisplay) && (
                        <RemoveApproval
                            row={tableProps.row}
                            timesData={[...leaveData]}
                            setTimesData={setLeaveData}
                        />
                    )}
                </>
            },
        ],
        [leaveData]
    );

    const TimesTable = () => {
        if(isTimesLoaded && isProjectsLoaded) {
            return <>
                <TableView columns={timesCols} data={timesData} tableType='timesheet'/>
            </>    
        } else if (!isTimesLoaded || !isProjectsLoaded)  {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading timesheets...</em></td></tr></tbody></Table>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}>No timesheets found</td></tr></tbody></Table>
        }
    }

    const LeaveTable = () => {
        if(isLeaveLoaded) {
            return <>
                <TableView columns={leaveCols} data={leaveData} tableType='leave'/>
            </>    
        } else if (!isLeaveLoaded) {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading leave requests...</em></td></tr></tbody></Table>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}>No leave requests found</td></tr></tbody></Table>
        }
    }

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)
      
        return (
            <span>
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={'Search'}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                        width: '100%',
                    }}
                />
            </span>
        )
    }

    function TableView ({ columns, data, tableType }) {

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
            state,
            setFilter,
            preGlobalFilteredRows,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageIndex: 0,
                    pageSize: 200,
                    hiddenColumns: columns.map(column => {
                        if (column.show === false) {
                            return column.accessor || column.id;
                        }
                    }),
                },            
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        useEffect(() => {
            // if (userIsAdmin) {
            setFilter("personDisplay", userFilterValue);
            // }
        }, [setFilter]);

        // const [pageFilter, setPageFilter] = useState({
        //     "value" : "20",
        //     "label" : "Show 20",
        // });

        // const handlePageFilter = (e) => {
        //     setPageFilter(e);
        //     setPageSize(Number(e.value));
        // }

        return (
            <Container>

                <div>&nbsp;</div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {/* <CSVLink className='cont' filename="lote-clients.csv" data={csvData}>
                    <i className="fas fa-file-excel"></i>
                </CSVLink>                 */}
                <div>&nbsp;</div>

                <Table {...getTableProps()}>
                    
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="fas fa-sort-down"></i>
                                        : <i className="fas fa-sort-up"></i>
                                    : ''}
                                </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div className="pagination">
                    <span>
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-double-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => nextPage()} disabled={!canNextPage}>
                            <i className="fas fa-angle-right"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <i className="fas fa-angle-double-right"></i>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            Page&nbsp;
                                <strong>{pageIndex + 1}</strong> of {pageOptions.length}
                        </span>
                    </span>
                    {/* <Select 
                        styles={customStyles}
                        className="right-select"
                        options={paginationOptions}
                        value={pageFilter}
                        onChange={e => {handlePageFilter(e)}}
                    /> */}
                </div>
            </Container>
        )
    }

    function handleTabSelect (selectedKey) {
        setKey(selectedKey);
        clearChecks();
    }

    function handleUserFilter(e) {
        setUserFilter(e);
        setUserFilterValue(e.value);
        clearChecks();
        calcHours(e.value, dateStart, dateEnd);
    }

    function handleDateStart(date) {
        date.setHours(0,0,0);
        setDateStart(date);
        calcHours(userFilterValue, date, dateEnd);
    }

    function handleDateEnd(date) {
        date.setHours(23,59,59);
        setDateEnd(date);
        calcHours(userFilterValue, dateStart, date);
    }

    const clearChecks = () => {
        var inputs = document.getElementsByTagName("input");
        for (var i = 0; i < inputs.length; i++) {
            if(inputs[i].type === "checkbox") {
                inputs[i].checked = false; 
            }  
        }
    }

    const calcHours = (userFilterVal, dateStartFilterVal, dateEndFilterVal) => {
        if (userFilterVal === 'sigma') {
            userFilterVal = userFilterValue;
            dateStartFilterVal = dateStart;
            dateEndFilterVal = dateEnd;
        }
        const filterTimes = times.filter((obj) => ((obj.attributes.active === false) && 
                                                    (obj.attributes.user.id === userFilterVal) && 
                                                    (obj.attributes.start >= dateStartFilterVal) && 
                                                    (obj.attributes.end <= dateEndFilterVal)));
        var appHours = 0;
        var penHours = 0;

        for (let i = 0; i < filterTimes.length; i++) {
            const sheet = filterTimes[i];
            if (sheet.attributes.approved === true) {
                appHours += sheet.attributes.hours;
            } else {
                penHours += sheet.attributes.hours;
            }
        }

        setApprovedSum(appHours);
        setPendingSum(penHours);
    }

    const selectSheets = (e) => {
        var inputs = document.getElementsByTagName("input");
        for (var i = 0; i < inputs.length; i++) {
            var idSplit = inputs[i].id.split(':');
            if (key === 'leave') {
                if(idSplit[0] === 'leave' && idSplit[1] === userFilterValue) {
                    inputs[i].checked = true; 
                }  
            } else if (key === 'times') {
                if(idSplit[0] === 'sheet' && idSplit[1] === userFilterValue) {
                    inputs[i].checked = true; 
                }  
            }
        }
    }

    const approveSheets = (e) => {
        e.preventDefault();
        // setSubmitString(<>
        //     Submitting&nbsp;
        //     <Spinner
        //         as="span"
        //         animation="border"
        //         size="sm"
        //     />
        // </>);

        var sheetArr = [];
        var sheetIndexArr = [];

        var inputs = document.getElementsByTagName("input");
        for (var i = 0; i < inputs.length; i++) {
            if(inputs[i].checked === true) {
                sheetArr.push(inputs[i].id.split(':')[2]);
                sheetIndexArr.push(inputs[i].id.split(':')[3])
            }  
        }

        for (let index = 0; index < sheetArr.length; index++) {
            const element = sheetArr[index];

            const Timesheet = Parse.Object.extend('Timesheet');
            const query = new Parse.Query(Timesheet);
            query.get(element)
            .then(function(timesheet) {
                timesheet.set('approved', true);
                timesheet.set('approvedBy', currentUser);
                timesheet.save()
                .then((res)=>{
                    if (res.attributes.leave === false) {
                        const dataCopy = [...timesData];
                        var thisSheet = dataCopy[sheetIndexArr[index]];
                        thisSheet.approvedDisplay = <i className="fas fa-check"></i>;
                        thisSheet.approvedByDisplay = currentUser.attributes.firstName + ' ' + currentUser.attributes.lastName;
                        thisSheet.isEditableDisplay = true;
                        dataCopy[sheetIndexArr[index]] = thisSheet;
                        setTimesData(dataCopy);
                        calcHours(userFilterValue, dateStart, dateEnd);
                    } else {
                        const dataCopy = [...leaveData];
                        var thisSheet = dataCopy[sheetIndexArr[index]];
                        thisSheet.approvedDisplay = <i className="fas fa-check"></i>;
                        thisSheet.approvedByDisplay = currentUser.attributes.firstName + ' ' + currentUser.attributes.lastName;
                        thisSheet.isEditableDisplay = true;
                        dataCopy[sheetIndexArr[index]] = thisSheet;
                        setLeaveData(dataCopy);
                    }
                }, (error) => {
                    alert('Please send screenshot of this error message: ' + error.message);
                });
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            });
            
        }
    }

    return <>
        {!userIsAdmin && (
            <Container>
                <Tab.Container defaultActiveKey='times' activeKey={key} onSelect={(k) => handleTabSelect(k)}>
                    <Nav justify variant='tabs'>
                        <Nav.Item>
                            <Nav.Link eventKey='times'>Timesheets</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey='leave'>Leave Requests</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey='times'>
                            <div className="table-homepage">
                                <div>
                                    <TimesTable />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='leave'>
                            <div className="table-homepage">
                                <div>
                                    <LeaveTable />
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Container>
        )}
        {userIsAdmin && (
            <Container>
                <Tab.Container defaultActiveKey='times' activeKey={key} onSelect={(k) => handleTabSelect(k)}>
                    <Nav justify variant='tabs'>
                        <Nav.Item>
                            <Nav.Link eventKey='times'>Timesheets</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey='leave'>Leave Requests</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Row className='mt-3'>
                        <Col md={4}>
                            <Select 
                                styles={customStyles}
                                options={staffOptions}
                                value={userFilter}
                                onChange={(e)=>handleUserFilter(e)}
                                placeholder='Team member'
                            />
                        </Col>
                        <Col md={2}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className='form-control'
                                selected={dateStart}
                                maxDate={new Date()}
                                onChange={(date)=> handleDateStart(date)}
                            />
                        </Col>
                        <Col md={2}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className='form-control'
                                selected={dateEnd}
                                maxDate={new Date()}
                                onChange={(date)=> handleDateEnd(date)}
                            />
                        </Col>
                        <Col md={4}>
                            <Stack direction='horizontal' gap={3}>
                                <Button variant="success" className='ms-auto'onClick={approveSheets}>Approve</Button>
                                <Button variant="primary" onClick={selectSheets}>Select all</Button>
                                <Button variant="outline-danger" onClick={clearChecks}>Unselect</Button>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col md={12}>
                            <Stack direction='horizontal' gap={3}>
                                <div className='ms-auto'>Approved hours: {approvedSum}</div>
                                <div>Pending hours: {pendingSum}</div>
                            </Stack>
                        </Col>
                    </Row>
                    <Tab.Content>
                        <Tab.Pane eventKey='times'>
                            <div className="table-homepage">
                                <div>
                                    <TimesTable />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='leave'>
                            <div className="table-homepage">
                                <div>
                                    <LeaveTable />
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Container>
        )}
    </>
}
